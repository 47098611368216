.message {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1.5px solid #ddd;
  cursor: pointer;

  &__unread {
    background-color: #f3f4f6;
  }

  &__left {
    display: flex;
    gap: 8px;
  }

  &__title {
    color: #1FA0BB;
    font-weight: 600;
  }

  &__time {
    color: black;
    font-size: 14px;
  }

  .flex__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  color: #8b8b8b;
  padding: 10px 0;
  margin-top: 20px;

  &__prev,
  &__next {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #2cb6d1;
    transition: opacity 0.2s ease-in-out;
    flex: 1;

    &:hover {
      opacity: 0.7;
    }
  }
  &__next {
    display: flex;
    justify-content: end;
  }

  &__numbers {
    display: flex;
    gap: 8px;
    justify-content: center;
    flex: 2;
  }

  &__number {
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #2cb6d1;
    }

    &.active {
      color: #2cb6d1;
      font-weight: 600;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.message:first-child {
  border-top: 1.5px solid #ddd;
}

.message:last-child {
  border-bottom: 1.5px solid #ddd;
}

.flex__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
}

.msg__title {
  font-size: 26px;
}

.font__semibold {
  font-weight: 400;
}

.msg__content {
  color: #6b7280;
}
