.assessment_container {
  min-height: calc(100vh - 340px);
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    .timer {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 26px;
      font-weight: 600;
      color: #2CB6D1;
    }
    
    .timed_out {
      color: #ed7a7a;
    }  
  }

  @media screen and (max-width: 1023px) {
    .header {
      flex-direction: column;
      align-items: baseline;

      a, button {
        margin-left: auto;
      }
      
      .timer {
        margin-left: auto;
        margin-top: 20px;
        float: right;
        font-size: 20px;

        svg {
          width: 22px;
        }
      }
    }
  }

  .navigation_section {
    margin-top: auto;
  }

  .grey_text {
    font-weight: 300;
    font-size: 15px;
    margin: 5px 5px 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #868686;

    a {
      color: #2CB6D1 !important;
    }

    .assessment_done{
      font-weight: 600;
      color: #393939;
    }
  }
}

.question_feedback {
  padding: 10px 20px;
  .answer {
    font-size: 12px;
    font-weight: 600;
    color: #A3ACAD;
  }

  .correct {
    background-color: #6BE3A2;
    border-radius: 10px;
  }

  .statement {
    margin-top: 10px;
    width: 100%;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: 300;
    color: #FFFFFF;

    .result {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .incorrect {
    background-color: #FC9C9C;
    border-radius: 10px;
  }
}

.page_count {
  margin: 10px 0;
  display: flex;
  justify-content: end;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.7;
}

h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

span, p {
  font-weight: 300;
}

strong {
  font-weight: 600;
  margin: 10px 0px !important;

}

.divider {
  margin: 35px 0;
  color: #868686;
}

.navigation_buttons {
  display: flex;
  justify-content: space-between;
  
  .back {
    align-self: flex-start;
  }
  
  .next {
    display: flex;
    gap: 10px;
    align-self: flex-end;
  }

  @media screen and (max-width: 1023px) {
    .next {
      flex-direction: column;
    }
  }
}

.progress {
  margin-top: 15px;

  .progress_status {
    font-size: 14px;
    font-weight: 600;
    color: #A3ACAD;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1023px) {
  .progress {
    margin-top: -25px;
  }
}


.pulse_wheel {
  content: '';
  width: 75px;
  height: 75px;
  border: 10px solid;
  display: inline-block;
  visibility: inherit;
  box-sizing: border-box;
  border-radius: 39px;
  animation: rotation 1s linear infinite;
}

.pulse_wheel_holder_child {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  display: flex;
  left: 0;
  top: 0;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.pulse_wheel_holder {
  position: relative;
}

.hidden {
  visibility: hidden;
}

.assessment_section {
  margin-top: 30px;
  background-color: #FFFFFF;
  padding: 25px;
  border-radius: 15px;
  
  .title_section {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 14px;
      display: flex;
    }

    .timer {
      font-size: 14px;
      font-weight: 300;
      border-radius: 5px;
      background-color: #2CB6D1;
      padding: 2px 7px;
      color: white;
      height: fit-content;
      width: fit-content;
    }
  }

  @media screen and (max-width: 1023px) {
    .title_section {
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .title {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }

  .divider {
    margin: 20px 0;
    color: #868686;
  }

  


  .questions_section {
    display: flex;

    .document_section {
      width: 50%;
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0px 0px 6px #00000029;
      max-height: 60vh;
      overflow-y: scroll;
      overflow-x: hidden;

      .document {
        img {
          width: 100%;
        }

        .meta {
          margin: 0 0 10px;
          text-align: left;

          b {
            color: #A3ACAD;
            font-size: 12px;
          }
        }
      }
    }

    .document_section::-webkit-scrollbar {
      width: 8px;
      
    }
    
    .document_section::-webkit-scrollbar-track {
      margin: 20px 0;
      background-color: #EDF2F4;
      border-radius: 20px;
      width: 10px;
    }
    
    .document_section::-webkit-scrollbar-thumb {
      background-color: #2CB6D1;
      border-radius: 20px;
    }
    

    .questions {
      width: 50%;
      padding: 30px 0 0 30px;

      .question {
        .text {
          display: flex;
          font-size: 14px;
          font-weight: 300;
          gap: 5px;

          b {
            font-weight: 600;
          }
        }

        .options {
          display: flex;
          margin: 20px 30px;
          gap: 30px;

          label {
            display: flex;
            font-size: 13px;
            gap: 5px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .feedback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
    height: 100% !important;

    .congratulations {
      font-size: 16px;
      font-weight: 600;
    }

    .feedback_text {
      width: 80%;
      margin: 20px 0px;
      font-size: 13px;
      font-weight: 300;
    }

    .input_field {
      margin: 30px;

      textarea {
        height: 150px;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .questions_section {
      flex-direction: column;

      .document_section {
        max-height: 55vh;
        width: 100%;
        overflow: scroll;
      }
      
      .questions {
        width: 100%;

        .question {
          .text {
            font-size: 16px;
          }

          .options {
            label {
              font-size: 16px;
            }
          }
        }
      }
    } 

    .feedback {
      padding: 0;

      .input_field {
        textarea {
          height: 150px;
          min-width: 330px !important;
        }
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  
  .cancel_button {
    align-self: flex-start;
    button {
      color: #BE272E;
      border: 2px solid #BE272E;
      font-weight: 600;
    }:hover {
      color: #FFF;
      background-color: #BE272E;
    }
  }
  
  .submit_button {
    button {
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 1023px) {
  .buttons {
    gap: 5px;
    
    button {
      font-size: 12px;
    }
  }
}

.feedback_buttons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  .back_button {
    width: 25%;
  }
  
  .pagination {
    width: 50%;
    display: flex;
    gap: 10px;
    color: #2CB6D1;
    cursor: pointer;
    justify-content: center;

    .active {
      color: #A3ACAD;
      font-weight: 600;
    }

    :hover {
      font-weight: 600;
    }
  }

  .next_button {
    display: flex;
    width: 25%;
    justify-content: end;
  }
}

.profile_step_container {
  display: flex;
  flex-direction: column;

  .step_container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;

    a {
      width: 90%;
      max-width: 550px;

      .step {
        display: flex;
        align-items: center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        height: 66px;
        padding: 20px;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.7s;

        .step_content{
          display: flex;
          align-items: center;

          svg {
            min-width: 28px;
          }

          .step_title {
            margin-left: 10px;
            font-weight: 600;
            font-size: 16px;
            color: #393939;
            line-height: 18px;
          }
        }

        button {
          padding: 15px;
          min-width: 100px !important;
        }
      }
    }

    .step:hover {
      transform: translate(0, -5px);
      color: #2CB6D1 !important;

      svg {
        fill: #2CB6D1 !important;
      }

      .step_title {
        color: #2CB6D1 !important;
      }

      button {
        background-color: #2CB6D1 !important;
        color: white !important;
      }
    }
  }
}
